.loading-wrapper
    margin: auto
    display: block
    height: 100%

.loading-overlay
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: white
    opacity: .5
    display: flex
    justify-content: center
    align-items: center
