.waitingroom
    display: flex
    align-items: center
    justify-content: center
    --heightDiscount: calc(var(--header) + calc((var(--paddingY) * 2)))
    min-height: calc(100vh - var(--heightDiscount, 180px))

.container
    --header: 0px
    --paddingy: 0px
    display: flex
    align-items: center
    justify-content: center
    padding: var(--paddingY) 0
    --heightdiscount: calc(var(--header) + calc(var(--paddingY) * 2))
    min-height: var(--containerHeight, calc(100vh - var(--heightDiscount,90px)))
